<template>
  <div>
    <div class="login-body">
      <div class="login-content">
        <div class="box-login-line">
          <div class="login-form">
            <ul class="login-nav">
              <li
                class="login-nav-item">
                <span>用户名密码登录</span>
              </li>
            </ul>

            <el-form
              ref="loginForm"
              :model="loginForm"
              :rules="loginRules"
              style="margin-top: 10px"
              :hide-required-asterisk="true"
              label-width="70px"
              @keyup.enter.native="handleSubmit('formInline')"
            >
              <el-form-item
                prop="userName"
                label="用户名："
              >
                <el-input
                  v-model="loginForm.userName"
                  placeholder="请输入用户名"
                ></el-input>
              </el-form-item>

              <el-form-item prop="password" label="密码：">
                <el-input
                  type="password"
                  v-model="loginForm.password"
                  placeholder="请输入密码"
                >
                </el-input>
              </el-form-item>

              <el-button
                style="width: 100%"
                type="primary"
                @click="handleSubmit"
                long
                >登 录
              </el-button>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      count: 0,
      curDate: +new Date(),
      loginForm: {
        userName: "",
        password: "",
      },
      loginRules: {
        userName: [
          { required: true, message: "用户名不能为空", trigger: "change" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 4, max: 6, message: "请输入正确的验证码", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    refreshCode() {
      
    },

    handleSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("handleLogin", this.loginForm)
            .then((res) => {// 登录成功
              // if (this.$store.state.user.userInfo.operationType !== 1) {
              //     this.$message.error("该账号暂时没有权限进入，请联系管理员！");
              //     return;
              //   }
              if (this.validatenull(this.$store.getters.permissions)) {
                this.$message.error("该账号暂时没有权限进入，请联系管理员！");
                return;
              }
               //this.$router.push("/user/list");
              let menu = this.$store.state.user.menu;
              if (menu[0]) {
                if(menu[0].children[0].path === '/statistics'){
                  this.$router.push(menu[1].children[0].path);
                }else {
                  this.$router.push(menu[0].children[0].path);
                }
                
              } else {
                this.$message.error("没有权限进入！请联系管理员");
              }
            });
        }
      });
    },
  
  },
  destroyed() {
    this.endInterVal();
  },

  created() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.refresh {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: #cccccc;
  color: #505050;
  font-size: 16px;
  text-align: center;
  line-height: 25px;
}

.footer {
  text-align: center;
  line-height: 25px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;

  span {
    margin-left: 15px;
  }
}

.login-nav {
  overflow: hidden;
  height: 30px;
  line-height: 28px;

  .login-nav-item {
    border-bottom: 2px solid #f1f1f1;
    text-align: center;

    span {
      padding: 0 10px;
      display: inline-block;
      height: 28px;
      cursor: pointer;
    }

    &.active {
      span {
        border-bottom: 2px solid #4ef1eb;
      }
    }
  }
}

.login-body {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: auto;
  overflow: hidden;
  padding-top: 4%;
  min-width: 1150px;

  .login-content {
    overflow: hidden;
    position: absolute;
    top: 30%;
    width: 1150px;
    margin: 0 auto;
    background-size: 25%;
    left: 50%;
    margin-left: -575px;
  }

  .box-login-line {
    border-radius: 5px;
    padding: 10px;
  }

  .login-form {
    background: #fff;
    border-radius: 5px;
    width: 360px;
    padding: 10px 15px 30px 10px;
    margin: auto;
  }
}
</style>
